.statistic-card {
  padding: 32px 16px;
  border-radius: $border-radius;

  &__title {
    color: inherit;
    margin-top: 10px;
  }
  &__value {
    white-space: nowrap;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
}
