.orders-table {
  .sbb-table-cell {
    padding: rem(24px 16px);
  }
  .sbb-table-row {
    .sbb-table-row-expand-icon-cell {
      svg {
        transition: 0.2s ease-in-out;
      }
    }
    &--expanded {
      .sbb-table-row-expand-icon-cell {
        svg {
          transform: rotateX(180deg);
        }
      }
    }
  }
  .sbb-table-expanded-row-level-1 {
    > td {
      padding: 0;
    }
    .sbb-table {
      border-radius: 0;
      .sbb-table-content {
        border-radius: 0;
      }
      .sbb-table-thead {
        background-color: $light-grey-200;
      }
      .sbb-table-row {
        background-color: $light-grey-100;
      }
    }
  }
}
