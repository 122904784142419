.select-field {
  &:focus {
    .ebs-select {
      background-color: $light-grey-300 !important;
    }
  }
  &.ebs-select__wrapper {
    border: none;
    width: fit-content;
  }
  .ebs-select {
    transition: $transition-time;
    border: none;

    &:hover {
      background-color: $light-grey-300 !important;
    }
  }

  &.ebs-select__options {
    width: fit-content !important;
    display: grid;
    grid-template-columns: 112px 1fr;
    padding: 16px;
  }

  .ebs-select {
    &__options-items {
      width: 112px;
      padding: 4px 0 8px 0;
      overflow: visible;
    }
    &__options-item.active {
      background-color: $primary-100;
    }
    .ebs-select__options-item-text {
      color: $primary-color;
    }
  }

  &__content {
    border-left: 1px solid $light-grey-300;
    padding: 0 0 16px 16px;
    margin-left: 12px;
  }
  &__footer {
    border-top: 1px solid $light-grey-300;
    grid-column: 1/3;
    padding-top: 12px;

    display: flex;
    justify-content: flex-end;
  }
}
