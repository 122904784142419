.application {
  border-radius: $border-radius;
  background-color: white;
  padding: rem(18px);
  height: 224px;
  width: 100%;
  max-width: 534px;
  transition: $transition-base;

  &:hover {
    cursor: pointer;
    box-shadow: $box-shadow;
  }

  &__icon {
    width: 56px;
    height: 56px;
    min-width: 56px;
    border-radius: $border-radius;
    color: #fff;
  }
}
