.page-title {
  background-color: $light-grey-100;
  padding: rem(12px 25px);
  margin: rem(-15px -25px 24px -25px);
  border-bottom: 1px solid $border-color;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin-bottom: 0;
  }
}
