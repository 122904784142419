.settings-card {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: rem(16px);
  min-height: 80px;
  &__thumb {
    min-width: 48px;
    width: 48px;
    height: 48px;
    border-radius: $border-radius;
    &--icon {
      background-color: $light-grey-100;
    }
  }
  &__description {
    font-size: 12px;
    color: $primary-color;
  }
  &__icon {
    color: $grey-100;
    height: 100%;
    width: 48px;
    height: 48px;
  }
}
