.breadcrumbs {
  display: flex;

  &__item {
    font-size: rem(12px);
    a {
      color: $text-color;
      &:hover {
        color: $primary-color;
      }
    }
    svg {
      margin: rem(0 12px);
    }
    &:last-child {
      a {
        pointer-events: none;
      }
      svg {
        display: none;
      }
    }
  }
}
