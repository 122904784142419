.dropdown {
  height: 100%;

  display: inline-block;
  position: relative;
  .ebs-button {
    & > svg {
      font-size: 12px !important;
    }
  }

  &__content {
    border: 1px solid red;
    background-color: #fff;

    border: 1px solid $light-grey-200;
    padding: 5px 0;
    border-radius: 5px;

    position: absolute;
    left: 0px;
    top: calc(100% + 0.5rem);
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    pointer-events: none;
    transition: transform 0.2s, opacity 0.07s;
    z-index: 3;

    &--right {
      left: initial;
      right: 0px;
    }
  }

  &:focus-within &__content {
    opacity: 1;
    transform: initial;
    pointer-events: initial;
    min-width: 100%;
  }
}
