.location-card {
  border: 1px solid $light-grey-200;
  padding: 16px;
  border-radius: $border-radius;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__status {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__value {
    padding: 12px 0;
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
  }
}
