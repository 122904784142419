.ebs-layout {
  &__sidebar--untoggled {
    .ebs-layout__container .ebs-layout__content-wrapper {
      width: calc(100% - 270px);
    }
  }
  &__top-bar-title::after {
    left: 269px;
  }
}

.ebs-sidebar {
  * {
    color: inherit !important;
  }
  &--toggled {
    .ebs-sidebar__text {
      margin-left: 20px;
    }
  }
  &--untoggled {
    transition: width $transition-time;
    width: 270px;
  }
  &__top {
    margin-top: 0;
    .ebs-tooltip {
      &__trigger {
        margin: 0;
        padding: 0;
      }
    }
  }
  &__item {
    &--collapsed.ebs-sidebar__item--active {
      color: $primary-color !important;
    }
    &.ebs-sidebar__toggler {
      margin: 0;
    }
    border-left: 3px solid transparent;
    color: $grey-400 !important;
    font-weight: 600;

    &:hover,
    &--active:not(.has-options) {
      .ebs-icon {
        color: $primary-color !important;
      }
      border-left: 3px solid $primary-color !important;
      background-color: $primary-50 !important;
      color: $primary-color !important;
    }
  }
  &__toggler {
    margin: 0;
  }
  &__prefix {
    margin-left: 20px;
    width: 20px;
  }
  &__text {
    transition: all $transition-time;
    padding: rem($gutter-size 27.5px $gutter-size 12px);
  }

  &__suffix path {
    fill: $grey-400;
  }

  .sub-menu-item {
    width: calc(100% - 56px);
    height: 24px;
    line-height: 12px;
    margin: 4px 4px 4px 40px;
    border-radius: $border-radius;
    position: relative;
    overflow: visible;
    transition: all $transition-time;

    &.ebs-sidebar__item--active,
    &:hover {
      border-color: transparent !important;
      &::before {
        background-color: $primary-color;
        border-color: $primary-color;
      }
    }
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      border: 1px solid $grey-200;
      border-radius: 4px;
      margin-left: 8px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: calc(100% + 10px);
      background: $light-grey-300;
      top: -5px;
      left: -10px;
    }
  }
}
