.dnd-card {
  max-width: 1088px;
}
.dnd-table {
  width: 100%;
  overflow: hidden;
  text-align: left;
  border-radius: $border-radius;
  &__header {
    background-color: $light-grey-100;
  }
  &__header-column {
    color: $grey-400;
    padding: rem(12px 10px);
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }
  &__body-column {
    padding: rem(24px 10px);
  }
  &__body-row {
    border-bottom: 1px solid $border-color;
  }
  &__primary-row {
    background-color: $primary-color;
    color: #fff;
  }
  &__secondary-row {
    background-color: $light-grey-100;
  }
  &__move-btn {
    color: $grey-100;
    cursor: move;
    &:hover {
      color: $text-color;
    }
  }
}
