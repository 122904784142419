.sbb-table {
  $self: #{&};

  overflow: auto;
  border-radius: $border-radius;
  background-color: #fff;

  table {
    table-layout: auto;
    width: 100%;
    border-collapse: collapse;
    font-weight: normal;
  }

  &-placeholder {
    .sbb-table-expanded-row-fixed {
      width: 100% !important;
    }
  }

  &-cell {
    padding: 0.5em 0;
    padding: rem(12px 16px);
    position: relative;
  }

  &-row {
    position: relative;
    border-bottom: 1px solid $light-grey-200;
    transition: 0.1s;

    &:last-child {
      border-bottom: none;
    }
    &--expanded {
      background-color: $light-grey-100;
    }
  }

  &-thead {
    text-align: left;
    background-color: $light-grey-100;
    color: $grey-400;
    #{$self}-cell {
      font-weight: 400;
      padding: rem(12px 16px);
      white-space: nowrap;
    }
  }

  &--has-title {
    #{$self}-title {
      padding: 0 0 1rem 0;
    }
  }

  &--has-footer {
    #{$self}-footer {
      padding: 20px 20px 0 20px;
      font-weight: 400;
    }
    #{$self}-row:last-child {
      border-bottom: 1px solid $light-grey-200;
    }
  }

  &--bordered {
    border: 1px solid $light-grey-200;
  }

  &--stripped {
    #{$self}-row:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.031);
    }
  }

  &--stripped-even {
    #{$self}-row:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.031);
    }
  }

  &--black-header {
    #{$self}-thead {
      background-color: rgba(0, 0, 0, 0.843);
      color: white;
    }
  }

  &--dense {
    #{$self}-thead #{$self}-cell {
      padding: 1rem 1rem;
    }
    #{$self}-cell {
      padding: 0.5rem 1rem;
    }
  }

  &--highlight-hover {
    #{$self}-row {
      cursor: pointer;
    }
    #{$self}-row:hover {
      background-color: rgba(0, 0, 0, 0.063);
    }
  }

  //   &--elevated {
  //     box-shadow: $box-shadow;
  //   }

  &-cell-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--white-title {
    .sbb-table-cell {
      padding: rem(8px 16px);
      &:first-child {
        padding: rem(8px 16px 8px 0);
      }

      &:last-child {
        padding: rem(8px 0 8px 16px);
        text-align: right;
      }
    }
    .sbb-table-thead {
      color: $grey-400;
      background-color: #fff;
      border-bottom: 1px solid $light-grey-200;
    }
  }

  .table-img {
    background-color: var(--light-grey-300);
    border-radius: 6px;
    overflow: hidden;
    height: 56px;
    width: max-content;
    border-radius: 2px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--one-row {
    .sbb-table-thead {
      color: $grey-400;
      background: #fff;

      &:first-child {
        padding: rem(0 16px 0 0);
      }
      &:last-child {
        padding: rem(0 0 0 16px);
      }
      .sbb-table-cell {
        padding: rem(0 16px);
      }
    }
    .sbb-table-cell {
      &:first-child {
        padding: rem(0 16px 0 0);
      }
      &:last-child {
        padding: rem(0 0 0 16px);
      }
    }
  }
  &--simple-list {
    thead {
      display: none;
    }
    .sbb-table-row {
      border: 0;
      td:first-child {
        padding: 0;
        width: 21px;
      }
    }
  }
  &--no-borders {
    thead {
      border-bottom: 1px solid $light-grey-200;
      color: $grey-400;
    }
    .sbb-table-row {
      border: 0;
    }
    tbody {
      .sbb-table-cell:first-child,
      .sbb-table-cell:nth-child(2) {
        text-align: center;
        width: 80px;
      }
    }
  }
}
