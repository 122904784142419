.permission-row {
  padding: rem(24px);
  border-radius: $border-radius;
  border: 1px solid $border-color;
}

.service-row {
  padding: rem(24px);
  border-radius: $border-radius;
  border: 1px solid $border-color;
  &--small {
    border: none;
    padding: rem(16px 0);
    .service-row__icon {
      height: 40px;
      min-width: 40px;
      width: 40px;
    }
  }
  &__icon {
    width: 56px;
    height: 56px;
    min-width: 56px;
    border-radius: $border-radius;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.success-invite-wrapper {
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  display: flex;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 400px;
}

.invite-people {
  max-width: "1088px";
}
