.card-info {
  position: relative;
  border-radius: $border-radius;
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--left {
      align-items: flex-start;
    }
  }
  &__title {
    text-transform: capitalize;
    font-weight: 500;
  }
  &__information {
    font-size: 12px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &--blue {
      color: $primary-400;
    }
  }
  &__key {
    color: $dark-grey-500;
  }
  &__icon {
    &--left {
      position: absolute;
      left: 15px;
      top: calc(50% - 10px);
    }
  }
}
