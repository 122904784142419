.tooltip-button {
  background-color: $dark-grey-400;
  color: $light-grey-100;
  .ebs-tooltip__body {
    padding: rem(8px);
    font-size: rem(14px);
  }
  .ebs-tooltip__arrow {
    path {
      fill: $dark-grey-400;
    }
  }
  &[data-popper-placement*="bottom"] {
    .ebs-tooltip__arrow {
      top: -0.8rem;
    }
  }
}
