.statistics-card {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  padding: 24px;
  min-height: 180px;
  height: 100%;
  width: 100%;
  flex-direction: column;

  &__title {
    white-space: nowrap;
    color: $text-color;
    font-size: 18px;
    font-weight: 600;
  }
  &__value {
    white-space: nowrap;
    word-spacing: -10px;

    font-size: 64px;
    font-weight: 700;
    line-height: 1;
    color: var(--color);
  }
  &__trend-direction {
    white-space: nowrap;
    margin-left: 1rem;

    color: $grey-400;
    font-size: 12px;
    font-weight: 400;

    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      margin-left: 6px;
    }
    &--up svg {
      color: green;
    }
    &--down svg {
      color: red;
      transform: rotateX(180deg);
    }
  }
}
