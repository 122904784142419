.pagination {
  font-size: 0.73rem;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: stretch;

  li:not(.selected):not(.disabled) {
    cursor: pointer;

    &:hover a {
      background-color: $light-grey-200;
    }
    &:active a {
      background-color: $light-grey-400;
    }
  }

  li a {
    color: black;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 0.75em;
    border-radius: $border-radius;
    border: 1px solid $light-grey-400;
    margin: 0 0.125em;
    transition: 0.2s;
    height: 100%;
    min-width: 3em;
    background-color: white;
  }

  li:focus-within:not(.disabled) a {
    box-shadow: 0px 0px 0px 2px $primary-200;
    border-color: transparent;
  }

  &__move a {
    padding: 0 0.125em !important;
    svg path {
      stroke: $grey-500;
    }
  }

  .selected a {
    background-color: $primary-color;
    color: white;
  }

  .disabled a {
    background-color: $light-grey-400;
    cursor: not-allowed;
  }
}
