.status {
  $self: #{&};
  text-transform: capitalize;
  &--success {
    color: $success-color;
    #{$self}__circle {
      background-color: $success-color;
    }
  }
  &--warning {
    color: $warning-color;
    #{$self}__circle {
      background-color: $warning-color;
    }
  }
  &--danger {
    color: $danger-color;
    #{$self}__circle {
      background-color: $danger-color;
    }
  }
  &--online {
    #{$self}__circle {
      background-color: $success-color;
    }
  }
  &--offline {
    #{$self}__circle {
      background-color: $grey-100;
    }
  }
  &__circle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-bottom: -1px;
    min-width: 8px;
  }
}
