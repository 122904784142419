@for $i from 1 through 9 {
  .fw-#{$i}00 {
    font-weight: $i * 100;
  }
}

@for $i from 1 through 50 {
  .font-size-#{$i} {
    font-size: $i + px;
  }
}
@for $i from 1 through 50 {
  .line-height-#{$i} {
    line-height: $i + px;
  }
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.full-height {
  height: 100%;
}

.grid-col-md-lg-2 {
  @media (min-width: $dashboard-grid-md) and (max-width: $dashboard-grid-md-lg) {
    grid-column: span 2;
  }
}

.grid-col-lg-xl-2 {
  @media (min-width: $dashboard-grid-lg) and (max-width: $dashboard-grid-xl) {
    grid-column: span 2;
  }
}

.g-xxl-xxxl-1 {
  @media (min-width: 1400px) and (max-width: 1600px) {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
}

.primary-color {
  color: $primary-color;
}

.grey-100 {
  color: $grey-100;
}
.grey-200 {
  color: $grey-200;
}
.grey-400 {
  color: $grey-400;
}
.black {
  color: $black;
}

.danger-color {
  color: $danger-color;
}

.success-color {
  color: $success-color;
}
.cursor-pointer {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed !important;
}

.flex-1 {
  flex: 1;
}

.text-center {
  text-align: center;
}

.divider {
  display: block;
  border-bottom: 1px solid $light-grey-200;
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}
.ml-a {
  margin-left: auto;
}
.mr-a {
  margin-right: auto;
}
.mt-a {
  margin-top: auto;
}

.pr-10 {
  padding-right: 10px;
}

@for $i from 1 through 100 {
  .max-w-#{$i*10} {
    max-width: $i * 10 + px;
  }
  .min-w-#{$i*10} {
    min-width: $i * 10 + px;
  }
}

.select-no-mp-right {
  .ebs-tooltip__trigger {
    margin-right: 0;
    padding-right: 0;
  }
}
.no-user-select {
  user-select: none;
}
.rotate-180 {
  transform: rotate(180deg);
}
.wait-rotation {
  transition: all 0.5s;
  &.trigger-rotation {
    transform: rotate(180deg);
  }
}

.no-events {
  pointer-events: none;
}

.active-item {
  border: 1px solid $primary-color !important;
}

.hide-outside-frame {
  position: absolute;
  top: -9999px;
}

.stretch-and-hide {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  text-indent: 100%;
}

// fix bug for select field
.ebs-select__options-height--300.ebs-select {
  &__options-items {
    max-height: 300px !important;
  }
}

.slim-scrollbar {
  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $border-color;
    border-radius: 5px;
    min-height: 100px;
  }
}
