//extra service form prefix
.service-form {
  // .ebs-input__wrapper.has-prefix .ebs-input {
  //   padding-left: rem(65px);
  // }
  // .ebs-input__prefix {
  //   background: #eef0f2;
  //   left: 0;
  //   height: 100%;
  //   color: $grey-400;
  //   padding: rem(0 16px);
  // }

  .edit-service-modal {
    .ebs-modal {
      margin: 90px auto 0 auto;
    }
    .ebs-modal__content {
      max-height: 60vh;
      overflow-y: auto;
    }
  }
}
