.top-bar {
  svg {
    color: $grey-100;
    transition: color $transition-time;
    &:hover {
      color: #252c32;
    }
  }

  &__tooltip {
    &__item {
      @include flex-center-vert;
      min-width: 140px;
      width: 100%;
      color: $grey-400;
      font-weight: 500;
      padding: 0.5rem;
      cursor: pointer;
      transition: $transition-time;
      svg {
        transition: $transition-time;
        color: $grey-100;
        margin-right: 7px;
      }

      &:hover {
        color: $primary-color;
        background-color: $primary-50;
        svg {
          color: $primary-color;
        }
      }
    }
  }

  .ebs-avatar__inline-alt {
    margin-left: 4px;
  }
}
