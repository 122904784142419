.button-group {
  &__button {
    background-color: $light-grey-100;
    font-weight: 400;
    color: $text-color;
    border: none !important;

    &--disabled {
      background-color: $light-grey-300;
      font-weight: 600;
    }

    .ebs-button {
        color: inherit;
        font-weight: inherit;
    }
  }
}
