.device-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  align-items: center;

  border: 1px solid $light-grey-200;
  border-radius: $border-radius;
  padding: 24px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__image {
    overflow: hidden;
    border-radius: 6px;
    background: $light-grey-100;
    padding: 1rem;
    img {
      width: 100%;
      min-height: 200px;
      height: 100%;
    }
    img:not(.no-image) {
      max-height: 400px;
      object-fit: contain;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 4px;
  }
  &__title {
    font-size: 14px;
    line-height: 24px;
    color: $grey-400;
  }
  &__value {
    white-space: nowrap;
  }
}
