.radio-box {
  position: relative;
  padding: 10px;
  border: 1px solid $light-grey-400;
  border-radius: 6px;
  .ebs-form__item {
    margin-bottom: 0;
  }
  //tooltip
  .ebs-tooltip {
    position: absolute;
    right: 10px;
    top: 10px;
    &__trigger {
      padding: 0;
      margin: 0;
      line-height: 0;
      cursor: pointer;
      transition: all $transition-time;
      &:hover svg {
        color: $dark-grey-400;
      }
      svg {
        color: $grey-100;
      }
    }
    &__body {
      padding: 0;
      font-size: 12px;
    }
  }

  //fake-radio
  .ebs-form__field__control {
    position: absolute;
    left: 10px;
    top: 10px;
    height: 100%;
  }
  .ebs-form__field__wrapper {
    margin: 0 1.2rem;
  }
  input {
    z-index: -1;
    opacity: 0;
  }
  &__label {
    cursor: pointer;
    position: absolute;
    left: 0;
    width: calc(100% - 50px);
    height: calc(100% - 20px);
    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: $light-grey-100;
      border: 1px solid $grey-100;
      border-radius: 50%;
    }
  }
}
input:checked + .radio-box__label::before {
  border: 6px solid $primary-color;
  border-radius: 50%;
}
