.input-time {
  &__input {
    .ant-picker-input {
      display: block;
    }
    font-size: 0.8rem;
    input {
      text-align: center;
      min-width: 40px;
    }
  }
  .ant-picker-time-panel-column::after {
    display: none;
  }
}
