.filters {
  display: inline-block;

  .ebs-button {
    min-height: rem(36px);
  }

  &__surface {
    background-color: white;
    border-radius: $border-radius;
    @include elevation-2;

    > section {
      padding: 1rem;

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }
  }

  &__tooltip-trigger {
    width: 170px;
  }

  &__tooltip {
    z-index: 999;
    transition: 0.2s;

    &:not(&--visible) {
      pointer-events: none;
      * {
        pointer-events: none !important;
      }
    }
  }

  &__tooltip-content {
    opacity: 0;
    transform: translateY(-10px);
    width: 320px;
    transition: 0.1s;
  }

  &__tooltip--visible &__tooltip-content {
    opacity: initial;
    transform: initial;
  }
}

.list-filter-container {
  max-height: 200px;
  overflow: auto;
}
