.cms {
  .ant-layout-header {
    padding: 0 15px 0 25px;
    box-shadow: inset 0px -1px 0px #ededed, inset -1px 0px 0px #ededed;

    .logo {
      width: 201px;
      padding-right: 23px;
      box-shadow: inset 0px -1px 0px #ededed, inset -1px 0px 0px #ededed;
    }
  }

  .ant-layout-content {
    position: relative;

    > .ant-spin {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .ant-menu.sticky-top {
    overflow: hidden;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    border-left: 4px solid transparent;
    margin: 0 !important;
    transition: border-color 350ms;

    &.ant-menu-item-selected {
      border-color: #1563e2;
    }

    .anticon {
      stroke: #9aa6ac;
      fill: #9aa6ac;
      color: #9aa6ac;
    }

    .anticon,
    span {
      transition: color 350ms;
    }
  }

  .ant-layout-sider-collapsed .ant-menu-item {
    padding: 0 !important;

    a {
      display: flex;
      justify-content: center;
      padding: 0;

      span + span {
        display: none;
      }
    }

    .anticon {
      min-width: 40px;
      background-color: transparent !important;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-menu-submenu {
    .ant-menu-item {
      padding-left: 24px !important;
    }
  }

  .ant-menu-submenu-title {
    font-weight: 600;
  }

  .ant-layout-sider-trigger {
    border-right: 1px solid #eef1f2;
  }

  .ant-menu-inline .ant-menu-item::after {
    border-width: 2px;
    border-color: #fff;
  }

  .ant-menu-inline .ant-menu-item.ant-menu-item-selected,
  .ant-menu-vertical .ant-menu-item.ant-menu-item-selected,
  .ant-menu-inline .ant-menu-item.ant-menu-item-active,
  .ant-menu-vertical .ant-menu-item.ant-menu-item-active {
    span {
      color: #1563e2;
    }
    .anticon {
      stroke: #1563e2;
      fill: #1563e2;
      color: #1563e2;
    }
  }

  .ant-table-wrapper {
    background-color: #fff;
    border: 1px solid #eef1f2;
    border-radius: 4px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.09);

    .ant-table {
      border-bottom: 1px solid #eef1f2;
    }

    .ant-table-pagination {
      padding: 0 24px;
    }
  }

  .ant-table-content {
    overflow: auto;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  .background-uploader .ant-upload {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 600px) {
    .ant-layout.ant-layout-has-sider {
      flex-direction: row-reverse;
      padding-bottom: 38px;

      .ant-layout-sider {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 2;
        padding: 0;
        max-width: 100% !important;
        width: 100% !important;
      }

      .ant-layout-sider-children {
        flex-direction: row;
        justify-content: center;

        .ant-menu {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          width: auto;

          &-item {
            margin: 0;
            padding: 0 !important;

            .anticon {
              margin: 0;
            }

            &.ant-menu-item-selected {
              border-left: none;
              border-top: 4px solid #1563e2;
            }

            .ant-menu-title-content span + span {
              display: none;
            }
          }
        }
      }

      .ant-layout-sider-trigger {
        display: none;
      }
    }
  }

  .ant-table-tbody > tr > td {
    width: calc(100% - 32px);
  }

  .ant-layout-sider-has-trigger.ant-layout-sider-collapsed {
    &,
    .ant-layout-sider-trigger {
      max-width: 49px !important;
      min-width: 49px !important;
    }
  }
}

.custom-tabs {
  background-color: #fff;

  &--hide-content .ant-tabs-content {
    display: none;
  }

  .ant-tabs-nav .ant-tabs-tab {
    &:first-child {
      margin-left: 16px;
    }
  }

  &.static-pages {
    .ant-tabs-content > .ant-tabs-tabpane {
      padding: 0 16px;
      margin-bottom: 16px;
    }

    > .ant-tabs-nav .ant-tabs-tab:nth-child(2) {
      margin-left: 16px;
    }
  }
}

.ant-layout-content {
  .ant-tabs-tab {
    margin-left: 16px;

    .ant-tabs-tab-btn {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

.ant-layout-header {
  .ant-tabs > .ant-tabs-nav {
    margin: 0;
  }
  .custom-tabs {
    margin-top: 20px;
    width: calc(100% - 162px);
    overflow: hidden auto;

    &--hide-content .ant-tabs-content-holder {
      display: none;
    }

    .ant-tabs-bar {
      margin: 0;
    }
  }
}

.sticky-top {
  position: sticky;
  top: 0;
}

.sticky-bottom {
  position: sticky;
  bottom: 48px;
}

.no-image {
  max-height: 200px;
  object-fit: cover;
}

.app-fallback {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.full-width {
  width: 100%;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: unset;
}

.ant-upload.ant-upload-select-picture-card {
  width: 50px;
  height: 50px;
}

.drag-tree {
  &__components {
    max-width: 262px;

    + .ant-col {
      max-width: 100%;
      flex: 1;
    }
  }
  &__node {
    border-bottom: 1px solid #f1f1f1;

    &:not(:first-child) {
      padding-bottom: 0;

      img {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    img {
      object-fit: contain;
    }

    .ant-tree-node-content-wrapper {
      position: relative;

      .ant-tree-iconEle {
        position: absolute;
        right: 0;
        pointer-events: none;
        width: auto;
        padding-right: 4px;

        .anticon-drag {
          opacity: 0.35;
          transition: opacity 350ms;
        }
      }

      .ant-tree-title {
        display: flex;
        height: 100%;
        z-index: 2;

        &,
        > div {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-right: 10px;
        }
      }
    }

    &:hover .ant-tree-iconEle .anticon-drag {
      opacity: 1;
    }
  }

  .ant-tree-node-content-wrapper {
    width: calc(100% - 24px);
  }

  .ant-tree-show-line .ant-tree-switcher {
    display: none;
  }

  .ant-row {
    flex-wrap: nowrap;

    .ant-col {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.full-screen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 8px;
  z-index: 9999;
  background: #fff;
  overflow: auto;

  .drop-tree {
    height: calc(100% - 16px);
  }
}

.drop-tree {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px dashed;
  border-color: rgba(232, 232, 232, 0.8);
  padding: 10px;
  height: calc(100% - 40px);
  transition: border-color 350ms;

  &:hover {
    border-color: #c9cccf;
  }

  .is-over .drop-tree__node-edit {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 2px;
      height: 2px;
      background-color: #1563e2;
    }

    &::after {
      content: "";
      position: absolute;
      width: 8px;
      bottom: 0;
      left: 0;
      height: 8px;
      border-radius: 100px;
      border: 2px solid #1563e2;
      background-color: white;
      margin-left: -4px;
      transform: translate3d(0, 1px, 0);
    }
  }

  &__node {
    .ant-tree-draggable-icon {
      &::before {
        content: "";
        position: absolute;
        background: darken(#d9d9d9, 25%);
        height: calc(100% - 40px);
        width: 1px;
        top: 40px;
        margin-left: -4px;
        margin-right: 7px;
      }
    }
    .anticon-delete {
      position: absolute;
      top: 0px;
      min-height: 35px;
      display: flex;
      align-items: center;
      right: 8px;
      opacity: 0.35;
      z-index: 3;
      transition: opacity 350ms;
    }

    &:hover .anticon-delete {
      opacity: 1;
    }

    &-edit {
      position: relative;
      display: flex;
      flex-direction: column;

      &__col.ant-form-item {
        height: auto !important;
      }

      > span {
        pointer-events: none;
        padding: 5px;

        img {
          padding-top: 32px;
          max-height: 200px;
          object-fit: contain;
          text-align: left;
          width: auto;
        }
      }

      .ant-upload.ant-upload-select-picture-card {
        margin-top: 8px;
      }

      .ant-dropdown-trigger {
        border-radius: 100px;
        width: 32px;
        padding: 0;
      }

      &__col {
        position: absolute;
        right: 15px;
        width: 104px;
        top: 1px;
        height: 30px;
        display: flex;
        align-items: center;

        .ant-dropdown-trigger {
          padding: 0 8px;
          width: auto;
        }
      }

      &__align {
        position: absolute;
        right: 15px;
        width: 104px;
        top: 1px;
        height: 30px;
        display: flex;
        align-items: center;

        .ant-dropdown-trigger {
          padding: 0 8px;
          width: auto;
        }
      }

      > div:not(.hide-options) {
        padding: 5px 0 2.5px;

        &:not(:first-child) {
          padding-top: 0;
        }
      }

      > .hide-options {
        .ant-form {
          background-color: transparent;
        }
      }

      .anticon-minus-circle {
        color: #f54d4f;
      }

      .ant-form {
        background: #fff;
        padding: 0 5px;
        width: calc(100% - 10px);

        > &-item {
          display: flex;
          margin: 0;

          &-control-wrapper {
            flex: 1;
          }
        }
      }

      span > .ant-row {
        width: calc(100% - 13px);
      }
    }

    span.ant-tree-node-content-wrapper {
      height: unset;
    }

    > .ant-row:not(.hide) {
      height: 100%;
    }

    .ant-upload img {
      height: 100%;
      max-height: 100px;
      object-fit: cover;
    }

    &.has-children {
      .ant-tree-switcher {
        margin-left: 8px;
      }
    }

    .ant-tree-switcher {
      display: flex;
      align-items: center;
      width: auto;
      height: 34px;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        font-size: 12px;
      }

      &-line-icon {
        display: none;
      }
    }

    .ant-upload-list-text {
      .anticon-delete {
        min-height: unset;
      }
    }
  }

  &.drop-tree--hide-switcher .ant-tree {
    &-node-content-wrapper {
      width: 100%;
      margin-left: 8px;
    }

    .ant-tree-switcher:empty {
      width: auto;
    }
  }

  .ant-tree {
    &-node-content-wrapper {
      width: calc(100% - 24px);
    }

    > li {
      padding: 0;
    }
  }
}

.ant-modal-close-x {
  height: 44px;
  width: 44px;
  line-height: 44px;
}

.ant-modal-body {
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 16px;
  }
}

.drag-tree,
.drop-tree {
  .ant-tree,
  .ant-tree-treenode {
    height: 100%;
    width: 100%;
  }

  .ant-tree-treenode {
    display: flex;

    .ant-tree-draggable-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      width: 34px;
    }

    &:last-child:first-child {
      padding: 0;
    }
  }

  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #f2f7ff;
  }
}

.icon-select .ant-dropdown-menu {
  display: flex;
  flex-wrap: wrap;
  max-width: 253.5px;

  .ant-dropdown-menu-item {
    border: 1px solid transparent;

    &.selected {
      border-color: #fcc000;
    }
  }
}

.col-select .ant-dropdown-menu-item {
  text-align: center;
}

.float-left {
  float: left;
}

.no-wrap {
  white-space: nowrap;
}

.flex {
  display: flex;
}

.flex-no-wrap {
  @extend .flex;
  align-items: center;
  flex-wrap: nowrap;
}

.ant-radio-wrapper {
  @extend .flex;
  align-items: center;
}

.ant-card-cover img {
  object-fit: cover;
}

.hide {
  height: 0;
  margin: 0 !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ebs-layout__content > .ant-spin {
  width: 100%;
  height: calc(100vh - 58px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-white {
  background-color: #fff;
}
