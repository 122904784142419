.select-calendar {
  &.react-datepicker-wrapper {
    display: none;
  }
  &__popper {
    position: static !important;
    transform: none !important;
    padding-top: 0 !important;

    .react-datepicker.ebs-datepicker__calendar {
      border: none;
      box-shadow: none;
    }
    .react-datepicker {
      &__month {
        margin: 0 !important;
        padding: 0 !important;
      }
      &__day-name {
        color: $grey-200;
        border-bottom: 1px solid $light-grey-300;
      }
      &__day--range-start {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      &__day--range-end {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }
}
