.counter-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $light-grey-100;
  justify-content: center;
  width: 100%;
  min-width: 80px;
  height: 80px;
  border-radius: $border-radius;
  &__value {
    font-size: 24px;
    color: $primary-color;
  }
  &__title {
    color: $grey-400;
    font-size: 12px;
  }
}
