.notifications {
  position: relative;
  .ebs-badge__type--danger .ebs-badge__container {
    background-color: $red;
  }
  &__outside {
    position: absolute;
    z-index: 10;
    top: -$gutter-size;
    left: -100vw;
    width: 200vw;
    height: 100vh;
  }

  &__window {
    position: absolute;
    right: 0;
    z-index: 10;
    padding: 16px 8px 16px 16px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
      0px 12px 24px rgba(91, 104, 113, 0.24);
    border-radius: 8px;
  }
  &__window-inner {
    max-height: 60vh;
    overflow-y: auto;
  }
  &__message {
    display: grid;
    grid-template-columns: 20px 1fr 20px;
    gap: 12px;
    width: 330px;
    background: $primary-50;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    padding: 20px 20px 16px 20px;
    border: 1px solid transparent;
    &--info {
      background: $primary-50;
      &--read {
        background: #fff;
        border: 1px solid $primary-100;
      }
    }
    &--success {
      background: $success-50;
      &--read {
        background: #fff;
        border: 1px solid $success-100;
      }
    }
    &--warning {
      background: $warning-50;
      &--read {
        background: #fff;
        border: 1px solid $warning-100;
      }
    }
    &--danger {
      background: $danger-50;
      &--read {
        background: #fff;
        border: 1px solid $danger-100;
      }
    }
  }
  &__title {
    text-transform: capitalize;
    font-weight: 600;
    color: $dark-grey-400;
    font-size: 1rem;
  }
  &__description {
    white-space: normal;
    font-size: 12px;
    line-height: 16px;
    color: $grey-400;
  }
  &__time {
    font-size: 12px;
    color: $grey-100;
    margin-top: 4px;
    margin-bottom: 0;
  }
  &__eye {
    cursor: pointer;
    color: $grey-100;
    font-size: 20px !important;
    transition: all $transition-time;
    margin: 0 0 0 auto;
  }
  &__close {
    cursor: pointer;
    color: $grey-100;
    font-size: 20px !important;
    transition: all $transition-time;
    margin: 0 0 0 auto;
  }
  &__loader {
    display: flex;
    justify-content: center;
  }
}
