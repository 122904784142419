.custom-tabs .ebs-tabs {
  width: auto;
  border: 0;
  &__item {
    margin: 0;
    padding: 4px 8px;
    background-color: $light-grey-100;
    border-bottom: none !important;
    &:first-child {
      border-start-start-radius: $border-radius;
      border-end-start-radius: $border-radius;
    }
    &:last-child {
      border-start-end-radius: $border-radius;
      border-end-end-radius: $border-radius;
    }
    &.active {
      font-weight: 600;
      background-color: $light-grey-300;
    }
  }
  &__content {
    padding: 0;
  }
}
