.steps {
  &__header {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: rem(24px);

    &__item {
      margin-right: rem(16px);
      font-weight: 500;
      color: $primary-color;
      display: flex;
      align-items: center;
      &:after {
        content: "";
        width: 30px;
        border-bottom: 1px dashed $light-grey-300;
        margin-left: rem(16px);
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:hover {
        color: $primary-color;
      }
      &--active {
        border-bottom: none;
        color: $primary-color;

        .steps__label__content__circle {
          border: 1px solid $primary-color;
          color: white;
          font-size: 12px;
          background-color: $primary-color;
        }
      }
      &:last-of-type {
        .steps__label__content__icon {
          display: none;
        }
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &--disabled {
      pointer-events: none;
      color: $grey-100;
      cursor: not-allowed;
    }
    &__content {
      display: flex;
      align-items: center;
      white-space: nowrap;
      &__circle {
        width: 22px;
        line-height: 20px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid $light-grey-500;
        color: $light-grey-500;
        margin-right: 8px;
      }
    }
  }
}
