.image-input {
  pointer-events: none;
  margin-bottom: 1rem;
  &__label {
    margin-bottom: 8px;
  }

  &__frame {
    flex-shrink: 0;
    width: 130px;
    height: 130px;
    background-color: $light-grey-300;
    border-radius: 6px;
    margin-right: 1rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__upload {
    position: relative;
    width: 100%;
    border: 2px dashed $light-grey-300;
    border-radius: 6px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .ebs-button {
    pointer-events: auto;
    color: #000;
    &__wrapper {
      border: 1px solid $border-color;
    }
  }
}
