:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --success-color: #{$success-color};
  --warning-color: #{$warning-color};
  --danger-color: #{$danger-color};

  // Accent colors
  --accent-color-1: #{$accent-color-1};
  --accent-color-2: #{$accent-color-2};
  --accent-color-3: #{$accent-color-3};

  // Primary colors
  --primary-100: #{$primary-100};
  --primary-200: #{$primary-200};
  --primary-300: #{$primary-300};
  --primary-400: #{$primary-400};
  --primary-500: #{$primary-500};
  --primary-600: #{$primary-600};
  --primary-700: #{$primary-700};
  --primary-800: #{$primary-800};
  --primary-900: #{$primary-900};

  --secondary-100: #{$secondary-100};
  --secondary-500: #{$secondary-500};

  // Semantic colors
  --success-100: #{$success-100};
  --success-200: #{$success-200};
  --success-300: #{$success-300};
  --success-400: #{$success-400};
  --success-500: #{$success-500};
  --success-600: #{$success-600};
  --success-700: #{$success-700};
  --success-800: #{$success-800};
  --success-900: #{$success-900};

  // semantic warning colors
  --warning-100: #{$warning-100};
  --warning-200: #{$warning-200};
  --warning-300: #{$warning-300};
  --warning-400: #{$warning-400};
  --warning-500: #{$warning-500};
  --warning-600: #{$warning-600};
  --warning-700: #{$warning-700};
  --warning-800: #{$warning-800};
  --warning-900: #{$warning-900};

  // semantic danger colors
  --danger-100: #{$danger-100};
  --danger-200: #{$danger-200};
  --danger-300: #{$danger-300};
  --danger-400: #{$danger-400};
  --danger-500: #{$danger-500};
  --danger-600: #{$danger-600};
  --danger-700: #{$danger-700};
  --danger-800: #{$danger-800};
  --danger-900: #{$danger-900};

  // accent 1 colors
  --accent-1-100: #{$accent-1-100};
  --accent-1-200: #{$accent-1-200};
  --accent-1-300: #{$accent-1-300};
  --accent-1-400: #{$accent-1-400};
  --accent-1-500: #{$accent-1-500};
  --accent-1-600: #{$accent-1-600};
  --accent-1-700: #{$accent-1-700};
  --accent-1-800: #{$accent-1-800};
  --accent-1-900: #{$accent-1-900};

  // accent 2 colors
  --accent-2-100: #{$accent-2-100};
  --accent-2-200: #{$accent-2-200};
  --accent-2-300: #{$accent-2-300};
  --accent-2-400: #{$accent-2-400};
  --accent-2-500: #{$accent-2-500};
  --accent-2-600: #{$accent-2-600};
  --accent-2-700: #{$accent-2-700};
  --accent-2-800: #{$accent-2-800};
  --accent-2-900: #{$accent-2-900};

  // accent 3 colors
  --accent-3-100: #{$accent-3-100};
  --accent-3-200: #{$accent-3-200};
  --accent-3-300: #{$accent-3-300};
  --accent-3-400: #{$accent-3-400};
  --accent-3-500: #{$accent-3-500};
  --accent-3-600: #{$accent-3-600};
  --accent-3-700: #{$accent-3-700};
  --accent-3-800: #{$accent-3-800};
  --accent-3-900: #{$accent-3-900};

  //grey colors dark
  --dark-grey-100: #{$dark-grey-100};
  --dark-grey-400: #{$dark-grey-400};
  --dark-grey-500: #{$dark-grey-500};

  //grey colors mid
  --grey-100: #{$grey-100};
  --grey-200: #{$grey-200};
  --grey-300: #{$grey-300};
  --grey-400: #{$grey-400};
  --grey-500: #{$grey-500};
  --grey-600: #{$grey-600};
  --grey-700: #{$grey-700};
  --grey-800: #{$grey-800};
  --grey-900: #{$grey-900};

  //grey colors light
  --light-grey-100: #{$light-grey-100};
  --light-grey-200: #{$light-grey-200};
  --light-grey-300: #{$light-grey-300};
  --light-grey-400: #{$light-grey-400};
  --light-grey-500: #{$light-grey-500};
}
