.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;

  // Full width and height
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // Label
  & + label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    user-select: none;
    transition: all $transition-time;

    &::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $grey-100;
      background-color: $light-grey-100;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }

  // Checked
  &:checked:not(&--indeterminate) {
    & + label::before {
      background-image: url("../../assets/check.svg");
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  // Indeterminate
  &--indeterminate + label {
    position: relative;

    &::before {
      background-image: none;
      border-color: $grey-100;
      background: transparent;
    }

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 5px);
      height: 2px;
      width: 10px;
      border-radius: 2px;
      background-color: $dark-grey-400;
    }
  }

  // Not disabled
  &:not(:disabled):not(:checked) + label:hover::before {
    border-color: $primary-200;
  }

  // Not disabled but active
  &:not(:disabled):active + label::before {
    background-color: $primary-200;
    border-color: $primary-200;
  }

  // Disabled
  &:disabled + label::before {
    background-color: $light-grey-500 !important;
    border-color: $grey-100 !important;
  }
}
