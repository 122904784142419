.brand-card {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}
.brand-header {
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid $border-color;
}
