// Text colors
$heading-color: #252c32;
$text-color: #252c32;
$text-color-secondary: #9aa6ac;
$text-color-light: #b0babf;

// Font
$font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 14px;
$font-size-desktop: 16px;
$font-size-small-desktop: 12px;

// Border
$border-radius: 6px;
$border-radius-sm: 4px;

// Headings size
$h1-font-size: 36px;
$h2-font-size: 24px;
$h3-font-size: 18px;
$h4-font-size: 14px;
$h5-font-size: 12px;

//Buttons
$btn-padding-y: 4px;
$btn-padding-x: 12px;

// button small height
$btn-padding-y-sm: 3px;
$btn-padding-x-sm: 8px;

// button large height
$btn-padding-y-lg: 8px;
$btn-padding-x-lg: 16px;

// Define font size of labels for forms
$field-label-font-size: 14px;

// Input
$input-padding-y: 4px;
$input-padding-x: 12px;

$input-padding-y-lg: 8px;
$input-padding-x-lg: 16px;

// Card
$card-header-bg-color: white;
$card-body-bg-color: #fff;
// $card-border-color: #fff;
$card-box-shadow: none;

$card-padding-x: 24px;
$card-padding-y: 24px;

$card-padding-x-lg: 40px;
$card-padding-y-lg: 40px;

//Collapse

$collapse-border-color: #fff;
$collapse-bordered-color: #fff;
$collapse-header-bg-color: #fff;
$collapse-body-bg-color: #fff;
$collapse-group-box-shadow: none;

//margin minus for cards
$mx-minus-26: -26px;
$my-minus-15: -15px;

$border-color: $light-grey-200;

$background-content: white;

$transition-time: 0.2s;

// Sidebar
$sidebar-width: 220px;
$layout-top-bar-height: 56px;

// Dashboard grid breakpoints
$dashboard-grid-md: 674px;
$dashboard-grid-md-lg: 878px;
$dashboard-grid-lg: 944px;
$dashboard-grid-xl: 1284px;
