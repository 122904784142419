.success-card {
  max-width: 440px;
  width: 100%;
  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 308px;
  }
  &__icon {
    width: 72px;
    height: 72px;
    border-radius: $border-radius;
    background-color: $primary-50;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(8px);
  }
  &__title {
    max-width: 360px;
    font-size: rem(24px);
    text-align: center;
    line-height: rem(32px);
    margin-bottom: rem(12px);
  }
}
