//---Card
//style card header
.ebs-card--medium .ebs-card__header {
  padding: rem(28px 24px 0px 24px);
  border: none;
}

//---Layout
//set layout min heigh full display height
.ebs-layout {
  &__top-bar {
    position: relative;
    z-index: 999;
  }
  .ebs-layout__content {
    background-color: $light-grey-100;
    min-height: calc(100vh - 58px);
    position: relative;

    //extend menu width
    &__sidebar--untoggled {
      .ebs-layout__container .ebs-layout__content-wrapper {
        width: calc(100% - 270px);
      }
    }
    &__top-bar-title::after {
      left: 269px;
    }
  }
}

//---Sidebar
.ebs-sidebar__item:hover:not(.has-options) .ebs-sidebar__text,
.ebs-sidebar__item:hover:not(.has-options) .ebs-icon,
.ebs-sidebar__item--active:not(.has-options) .ebs-sidebar__text,
.ebs-sidebar__item--active:not(.has-options) .ebs-icon {
  color: $primary-color;
}

//---Table
//table border radius
.sbb-table .sbb-table-content {
  border-radius: $border-radius;
}

//---Inputs
.ebs-select__wrapper.active .ebs-select-dropdown__wrapper .ebs-select,
.ebs-input__wrapper--active,
.ebs-input__wrapper.focus {
  border-color: $light-grey-400;
}

.ebs-input__wrapper {
  &:focus-within {
    border: 1px solid $primary-color;
  }
}
.ebs-textarea__wrapper {
  .ebs-textarea:focus {
    border: 1px solid $primary-color;
  }
}
.ebs-select__wrapper:not(.select-field) {
  &:focus-within {
    .ebs-select-dropdown__wrapper .ebs-select {
      border: 1px solid $primary-color;
    }
  }
}

.ebs-input__container {
  //fix input border radius
  .ebs-input__wrapper {
    overflow: hidden;
    display: flex;
    &.has-prefix {
      .ebs-input {
        padding-left: rem(5px);
      }
    }
  }

  .ebs-input__prefix {
    color: $grey-400;
    background-color: $light-grey-200;
    position: initial;
    transform: none;
    top: auto;
    left: auto;
    padding: rem(0 5px);
    min-width: 40px;
    text-align: center;
    > * {
      width: 100%;
    }
  }
}

//select
.ebs-select-dropdown__wrapper {
  min-height: rem(36px);
}
.ebs-select__wrapper.disabled .ebs-select-dropdown__wrapper .ebs-select {
  border-color: $border-color !important;
  background-color: $border-color !important;
  .ebs-icon {
    color: $grey-100;
  }
}

.ebs-input__wrapper.disabled {
  border-color: $light-grey-500;
  background-color: $border-color;
}

.ebs-input__container {
  width: 100%;
}

//Modal
//border fix
.ebs-modal {
  overflow: hidden;
}

//Buttons
.ebs-button--large .ebs-button {
  font-weight: 100;
}
.ebs-button--prefix button.ebs-button {
  text-align: left;
}

.ebs-button {
  &__wrapper.ebs-button--round {
    border-radius: 50px;
  }
  &--light.ebs-button__wrapper {
    background-color: white;
    border-color: $light-grey-400;
    color: $dark-grey-400;

    .ebs-button,
    .ebs-icon {
      color: $dark-grey-400;
    }

    &:hover {
      border-color: $light-grey-400;
      background-color: $light-grey-300;
      color: $dark-grey-400;

      .ebs-button,
      .ebs-icon {
        color: $dark-grey-400;
      }
    }

    &:active,
    &.active {
      border-color: transparent;
      background-color: $light-grey-400;
      color: $dark-grey-400;

      .ebs-button,
      .ebs-icon {
        color: initial;
      }
    }

    .ebs-icon {
      color: $dark-grey-400;
    }
  }

  &--ghost & {
    color: $primary-color;
  }

  &--text {
    background-color: transparent;
    color: $dark-grey-400;

    &:hover {
      background-color: $light-grey-200;

      .ebs-button,
      .ebs-icon,
      svg {
        color: $dark-grey-400;
      }
    }

    &:active,
    &.active {
      background-color: $light-grey-400;
      border: 1px solid red;

      .ebs-button,
      .ebs-icon,
      svg {
        color: $dark-grey-400;
      }
    }
    &:focus-within {
      svg {
        color: $dark-grey-400;
      }
    }

    .ebs-icon {
      color: $dark-grey-400;
    }
  }

  &--text & {
    color: $grey-100;
  }

  &__wrapper:focus-within {
    box-shadow: none;
  }
  &__group &__wrapper:focus-within {
    z-index: 2;
  }

  &--ghost:focus-within,
  &--text:focus-within {
    box-shadow: none;
    border-color: transparent;
    background-color: $light-grey-300;
  }
}

.ebs-button--ghost.ebs-button__wrapper:active .ebs-button,
.ebs-button--ghost.ebs-button__wrapper:active .ebs-icon,
.ebs-button--ghost.ebs-button__wrapper.active .ebs-button,
.ebs-button--ghost.ebs-button__wrapper.active .ebs-icon {
  color: $dark-grey-400;
}

// sort by component
.ebs-sort-by__wrapper .ebs-button--ghost .ebs-button .ebs-icon {
  color: $dark-grey-400;
}

.ebs-sort-by__wrapper:hover .ebs-button--ghost .ebs-button .ebs-icon {
  color: $dark-grey-400;
}

//react simple image viewer
div.react-simple-image-viewer__modal {
  z-index: 9999;
  background-color: rgba($dark-grey-400, 0.8);
}

//sidebar menu
.ebs-sidebar__suffix .ebs-icon--arrow-left {
  transform: rotate(180deg);
}
.ebs-sidebar__suffix .ebs-icon--arrow-bottom {
  transform: rotate(0deg);
}

// notify
.ebs-notify {
  &__item-title {
    margin-right: 16px;
  }
  .ebs-button {
    &--text {
      background-color: transparent;
      color: white;
      &:hover {
        background-color: transparent;
        .ebs-button,
        .ebs-icon {
          color: $dark-grey-400;
        }
      }
      &:active,
      &.active {
        background-color: transparent;

        .ebs-button,
        .ebs-icon {
          color: $dark-grey-400;
        }
      }
      .ebs-icon {
        color: white;
      }
    }
  }
}

.ebs-checkbox {
  border-radius: 3px;
  height: 1.2rem;
  width: 1.2rem;

  &::before {
    background-color: transparent !important;
  }
}

.ebs-checkbox__input:checked ~ .ebs-checkbox .ebs-checkbox__check {
  font-size: 0.9rem;
}

$checkbox-d-path: path("M6 18L13 24L26 10");

@supports (d: $checkbox-d-path) {
  .ebs-checkbox__check path {
    d: $checkbox-d-path;
    stroke-width: 6;
    transform: none;
    fill: none;
  }
}

.ebs-checkbox__input:hover ~ .ebs-checkbox {
  border-color: #b0babf;
}

.ebs-checkbox__wrapper {
  &:hover .ebs-checkbox {
    border-color: #b0babf;
  }
  &:focus-within .ebs-checkbox {
    box-shadow: 0px 0px 0px 2px $primary-200;
    border-color: transparent;
  }
}
