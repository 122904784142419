.map-form {
  display: grid;
  min-height: calc(100vh - 500px);
  grid-template-columns: 400px 1fr;
  grid-template-rows: min-content;
  column-gap: 30px;
  &__last-block {
    margin-top: auto;
    padding-top: 20px;
  }
  .map {
    grid-row: 1/ 3;
    grid-column: 2/-1;
    margin-bottom: 0;
    height: 100%;
    & > .row {
      height: 100%;
      .col:first-child {
        height: 2rem;
      }
      .col:nth-child(2) {
        height: calc(100% - 2rem);
      }
    }
  }
}

@media (max-width: 1200px) {
  .map-form {
    grid-template-columns: 1fr;
    column-gap: unset;
    &__last-block {
      order: 10;
      margin-top: auto;
    }
    .schedule-form {
      &__input {
        margin-bottom: 10px;
      }
    }
    .map {
      grid-row: unset;
      grid-column: unset;
      height: 400px;
      margin: 20px 0;
      order: 9;
    }
  }
}
