.custom-tooltip {
  padding: rem(8px);
  background: #ffffff;
  border-radius: rem(8px);
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
    0px 8px 16px rgba(91, 104, 113, 0.24);

  &__type {
    width: rem(6px);
    height: rem(6px);
    border-radius: 100%;
    margin-top: calc(1rem / 2);
  }

  &__title {
    color: $grey-400;
  }

  &__description {
    color: $text-color;
    font-weight: 600;
  }
}

@mixin hover-chart {
  transition: 0.2s;
  &:hover {
    opacity: 0.6;
  }
}

.bar-chart {
  .recharts-rectangle {
    @include hover-chart();
  }
  .recharts-legend-item {
    display: inline-flex !important;
    align-items: center;
  }
}

.pie-chart {
  .recharts-sector {
    @include hover-chart();
  }
  .recharts-legend-item {
    display: flex !important;
    align-items: center;
  }
}
