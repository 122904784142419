$primary-color: #1563e2;
$secondary-color: #9aa6ac;
$success-color: #47d16c;
$warning-color: #f8dd4e;
$danger-color: #f76659;

// Accent colors
$accent-color-1: #c479f3;
$accent-color-2: #38d9b9;
$accent-color-3: #f8dd4e;

// Primary colors
$primary-50: #f2f7ff;

$primary-100: #cfe8fd;
$primary-200: #a0cefc;
$primary-300: #70adf6;
$primary-400: #4b8fed;
$primary-500: $primary-color;
$primary-600: #0f4cc2;
$primary-700: #0a38a2;
$primary-800: #062783;
$primary-900: #041b6c;

$secondary-100: #eff6f9;
$secondary-200: #e1eef3;
$secondary-300: #c2d2db;
$secondary-400: #9dadb7;
$secondary-500: $secondary-color;
$secondary-600: #506274;
$secondary-700: #374a61;
$secondary-800: #23344e;
$secondary-900: #152340;

// Semantic colors
$success-50: #f5fff8;

$success-100: #dffcdb;
$success-200: #b9fab8;
$success-300: #91f198;
$success-400: #72e386;
$success-500: $success-color;
$success-600: #33b362;
$success-700: #239658;
$success-800: #16794d;
$success-900: #0d6446;

// semantic warning colors
$warning-50: #fffeeb;

$warning-100: #fefbdb;
$warning-200: #fef6b8;
$warning-300: #fcef94;
$warning-400: #fae879;
$warning-500: $warning-color;
$warning-600: #d5b939;
$warning-700: #b29727;
$warning-800: #8f7718;
$warning-900: #775f0e;

// semantic danger colors
$danger-50: #fffbfa;

$danger-100: #feebde;
$danger-200: #fed2bd;
$danger-300: #fcb39b;
$danger-400: #fa9582;
$danger-500: $danger-color;
$danger-600: #d44141;
$danger-700: #b12c37;
$danger-800: #8f1c2f;
$danger-900: #76112a;

// accent 1 colors
$accent-1-100: #fae4fe;
$accent-1-200: #f3cafd;
$accent-1-300: #e7affb;
$accent-1-400: #d99af7;
$accent-1-500: $accent-color-1;
$accent-1-600: #9b58d0;
$accent-1-700: #753cae;
$accent-1-800: #53268c;
$accent-1-900: #3a1774;

// accent 2 colors
$accent-2-100: #d7fde7;
$accent-2-200: #b0fbd7;
$accent-2-300: #87f3c9;
$accent-2-400: #66e8c2;
$accent-2-500: $accent-color-2;
$accent-2-600: #28baab;
$accent-2-700: #1c9c9b;
$accent-2-800: #11747d;
$accent-2-900: #0a5768;

// accent 3 colors
$accent-3-100: #fefbdb;
$accent-3-200: #fef6b8;
$accent-3-300: #fcef94;
$accent-3-400: #fae879;
$accent-3-500: $accent-color-3;
$accent-3-600: #d5b939;
$accent-3-700: #b29727;
$accent-3-800: #8f7718;
$accent-3-900: #775f0e;

//grey colors dark
$dark-grey-100: #48535b;
$dark-grey-400: #252c32;
$dark-grey-500: #1a2024;

//grey colors mid
$grey-100: #b0babf;
$grey-200: #9aa6ac;
$grey-300: #84919a;
$grey-400: #6e7c87;
$grey-500: #5b6871;
$grey-600: #425261;
$grey-700: #2d3e51;
$grey-800: #1d2c41;
$grey-900: #111e36;

//grey colors light
$light-grey-100: #f6f8f9;
$light-grey-200: #eef0f2;
$light-grey-300: #e5e9eb;
$light-grey-400: #dde2e4;
$light-grey-500: #d5dadd;

$orange: #f68d2e;
$red: #f2271c;
