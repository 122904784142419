.color-picker {
  position: relative;
  &__color-wrapper {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: $border-radius;
    box-shadow: inset 0px 0px 0px 0.5px rgba(#000, 0.2);
    background-repeat: repeat;
    overflow: hidden;
    &__color {
      height: 32px;
      box-shadow: inset 0px 0px 0px 0.5px rgba(#000, 0.2);
      border-radius: $border-radius;
    }
  }
  &__color-value {
    flex: 1;
    height: 32px;
    .ebs-input__container {
      height: 100%;
    }
    &.ebs-input__wrapper {
      &.focus {
        border-color: $border-color;
      }
      &--active {
        border-color: $border-color;
      }
    }
  }
  &__color-type {
    width: 247px;
    &.ebs-select__wrapper.active .ebs-select-dropdown__wrapper .ebs-select {
      border-color: $border-color;
    }
    .ebs-icon {
      color: $grey-100;
    }
  }
  .react-colorful {
    width: auto;
    &__alpha {
      background-repeat: repeat;
    }
    &__alpha-pointer {
      background-repeat: repeat;
    }
    &__saturation {
      border-radius: $border-radius;
      border-bottom: 0;
    }
    &__hue {
      border-radius: 10px;
      margin: 18px 0 8px 0;
      height: 12px;
      width: calc(100% - 47px);
      box-shadow: inset 0px 0px 0px 0.5px rgba(#000, 0.2);
    }
    &__last-control {
      border-radius: 10px;
      height: 12px;
      width: calc(100% - 47px);
      box-shadow: inset 0px 0px 0px 0.5px rgba(#000, 0.2);
    }
    &__pointer {
      border: 3px solid #fff;
      width: 16px;
      height: 16px;
      box-shadow: 0px 0px 0px 1px rgba(#000, 0.25);
    }
  }
}
