.auth-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: $light-grey-100;

  &__auth-card {
    width: 470px;
  }
}
