.react-multi-email {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  text-align: left;
  padding: rem(0 10px);
  background: #fff;
  border: 1px solid $border-color;
  color: $text-color;
  border-radius: $border-radius;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;

  > span[data-placeholder] {
    display: none;
    position: absolute;
    left: 10px;
    top: 8px;
    background-color: #fff;
  }

  &.focused {
    border-color: $primary-color;
    background: #fff;
  }

  &.empty > span[data-placeholder] {
    display: flex;
    width: 100%;
    color: $grey-100;
    height: 100%;
    top: 0;
    align-items: center;
  }

  &.focused > span[data-placeholder] {
    display: none;
  }

  > input {
    width: auto !important;
    outline: none !important;
    border: 0 none !important;
    display: inline-block !important;
    line-height: 1;
    vertical-align: baseline !important;
    padding: rem(11px 0) !important;
    color: $text-color;
  }

  [data-tag] {
    vertical-align: baseline;
    margin: rem(2px 4px);
    background-color: $light-grey-300;
    background-image: none;
    padding: rem(3px 8px);
    color: $text-color;
    text-transform: none;
    font-weight: 600;
    border: 0 solid transparent;
    border-radius: rem(30px);
    transition: background 0.1s ease;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;

    [data-tag-item] {
      max-width: 100%;
      overflow: hidden;
    }

    &:first-child {
      margin-left: 0;
    }

    [data-tag-handle] {
      margin-left: rem(8px);
      cursor: pointer;
    }
  }
  .remove-email {
    color: $grey-500;
    transition: color 0.1 ease;
    &:hover {
      color: $text-color;
    }
  }
}
