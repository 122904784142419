.payout-status {
  display: flex;
  align-items: center;
  $self: #{&};
  &--completed {
    #{$self}__circle {
      background-color: $primary-color;
    }
  }
  &--processing {
    #{$self}__circle {
      background-color: $warning-color;
    }
  }
  &__circle {
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    border-radius: 4px;
  }
}
