.ebs-form__field {
  .react-tel-input {
    @include fontRegular;
    font-size: $font-size-base;
  }
  .custom-phone-input {
    &__button,
    &__button.flag-dropdown {
      z-index: 10;
      border: 1px solid $light-grey-400;
      border-radius: rem($border-radius) 0 0 rem($border-radius);

      .selected-flag {
        border-radius: rem($border-radius) 0 0 rem($border-radius);
      }
    }
    &__input {
      width: 100%;
      border: 1px solid $light-grey-400;
      background-color: #fff;
      border-radius: rem($border-radius);

      &--large {
        max-height: rem($base-size-lg);
        padding-top: rem($input-padding-y-lg);
        padding-right: rem($input-padding-x-lg);
        padding-bottom: rem($input-padding-y-lg);
      }
      &:focus-within:not(.custom-phone-input__input--hasErrors) {
        border: 1px solid $primary-color;
      }
      &--hasErrors {
        border: 1px solid $danger-color;
      }
      &--disabled {
        color: $grey-500;
        background-color: $light-grey-200;
      }

      &:focus-within + .custom-phone-input__button.flag-dropdown {
        border: 1px solid #1563e2;
      }
    }
    &__dropdown {
      border-radius: $border-radius;
    }
    &__search {
      &.search {
        padding: 0 0 0 9px;
        margin: 0;
      }

      &-emoji {
        display: none;
      }
      &-box.search-box {
        width: 100%;
        margin: 0;
        padding: 7px 9px 7px 0;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $light-grey-400;
      }
    }
  }
}
