.diagnostic-criteria {
  display: flex;
  flex-wrap: nowrap;
  &__item {
    display: inline-block;
    width: 4px;
    height: 24px;
    margin-right: 4px;
    border-radius: 4px;
    &:last-child {
      margin: 0;
    }
    &--correct {
      background: $success-color;
    }
    &--wrong {
      background: $danger-color;
    }
  }
}
