.uploader {
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 100%;

  input {
    display: none;
  }
  &__block {
    margin-right: 16px;
    border: 1px solid $light-grey-400;
    background-color: white;
    border-radius: $border-radius;

    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  &__title {
    color: $grey-200;
    flex-grow: 1;
    background-color: transparent;
    border: 0;
    padding: 0 16px;
    display: flex;
    align-items: center;

    &--black {
      color: $dark-grey-400;
    }
  }

  &__file-name {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
  &__file-extension {
    display: inline-flex;
    justify-content: flex-start;
    width: 90px;
  }
  &__button {
    cursor: pointer;
    color: $dark-grey-400;
    border-left: 1px solid $light-grey-400;
    padding: 0 16px;

    display: flex;
    align-items: center;
  }
}
