.schedule-form {
  border-bottom: 1px solid $light-grey-200;

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    &::after {
      content: "";
      margin-left: 16px;
      flex-grow: 1;
      background-color: $light-grey-200;
      height: 1px;
    }
  }

  &__input {
    margin-bottom: 16px;

    .ebs-form__item {
      margin-bottom: 0;
    }
  }
  &__time {
    position: relative;

    .ebs-input__container {
      &:before {
        content: "";
        background: $light-grey-200;
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        height: 100%;
        width: 27.5px;
      }
    }
    input[type="time"] {
      position: relative;
      z-index: 10;
      &::-webkit-calendar-picker-indicator {
        background: none;

        cursor: pointer;
        position: absolute;
        top: 0;
        left: calc(var(--bs-gutter-x) / -2);
        width: 100%;
        height: 100%;
      }
    }
  }
}
