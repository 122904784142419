.brand-details {
  max-width: 888px;
  width: 100%;
  margin: 0 auto;
}

.success-brand-card-wrapper {
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  display: flex;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 400px;
}
.brand-wrapper {
  max-width: 888px;
  width: 100%;
}

.brand-table {
  width: 100%;
  th {
    text-align: left;
    color: $grey-400;
  }
  td,
  th {
    padding: rem(0 10px);
  }
}
.add-brands-table {
  max-width: 1200px;
  margin: 0 auto;
}
.model-table-head {
  width: 25%;
}
.model-card {
  width: 100%;
  &__featured-image {
    width: 124px;
    height: 224px;
    min-width: 124px;
    padding: rem(36px 25px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-grey-100;
    border-radius: $border-radius;

    img {
      max-width: 100%;
    }
  }
  &__brand-tables {
    width: calc(100% - 124px);
  }
}
