.horizontal-line {
  margin-bottom: rem(16px);
  background-color: $border-color;
  width: 100%;
  height: 0.5px;
}

.ssb-thumb {
  width: 40px;
  height: 40px;
  border-radius: $border-radius;
  &--bg {
    background-color: $light-grey-300;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.thumbnail-image {
  width: 40px;
  height: 40px;
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 5px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover {
    &:after {
      opacity: 0.4;
    }
  }
  &--bg {
    background-color: $light-grey-300;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
  }
}

.small-thumbnail {
  width: 32px;
  height: 32px;
  border-radius: $border-radius;
  background: $light-grey-300;
}

.select-modal {
  .ebs-form__item:last-child {
    div {
      height: 100%;
    }
    .ebs-form__field__wrapper {
      height: calc(100% - 2px);
    }
    .ebs-select {
      box-shadow: -1px 0px 0px 0px $primary-color;
      min-height: auto;
      border-radius: 0 0.42857rem 0.42857rem 0 !important;
      border-left: none !important;
    }
    .ebs-tooltip__trigger {
      padding: 0;
      margin: 0;
    }
  }
  .ebs-input__wrapper {
    border-radius: 0.42857rem 0 0 0.42857rem !important;
  }
}

.list-restart {
  list-style: none;
  padding: 2px;
  margin: 0;
}

// Remove tooltip animation
.ebs-tooltip__body {
  animation-fill-mode: none;
}
